//@media (min-width: map-get($breakpoints, lg)) and (max-width: map-get($breakpoints, xl)) {
//@media  (max-width: map-get($breakpoints, lg)) and (min-width: map-get($breakpoints, sm) + 1px) {

// Useful mixin for before and after
@mixin beforeafter {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

html {
  scroll-behavior: smooth;
}

body{
  position: relative;
}

// header

.headerContentWrapper{
  position: relative;
}
.content.headerContent{
  @media (min-width: map-get($breakpoints, lg)) {
    padding-top: $spacer*3;
    padding-bottom: $spacer*3; 
  } 
  &:before {
    @include beforeafter;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 33%);
    height: 3vw;
    background: $brand-secondary;
    top: 0;
  }
  @media (min-width: map-get($breakpoints, lg)) {
    &:after{
      @include beforeafter;
      clip-path: polygon(0 67%, 100% 0, 100% 100%, 0 100%);
      bottom: 0;
      height: 3vw;
      background: white;
    }
  }
}

// buttons

.cta-button,
.donateBtn,
.footerBox.NewsletterSign-up .groupSignUpBtn,
.button.backToTop,
.homefeaturecategory-homeboxquickgiving .quickGivingPanel .donateBtn,
#mc-embedded-subscribe {
  @media (min-width: map-get($breakpoints, xl)) {
    font-size: $font-size-large;
  }
  position: relative;
  background-image: url($assets-path + "arrow.svg");
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: right 20px center;
  padding: 0.5em 3em 0.5em 1em;
  &:before {
    @include beforeafter;
    transition: all .3s ease-in-out;
    clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
    top: -9%;
    height: 10%;
    background: $brand-secondary;
  }
  &:after{
    @include beforeafter;
    transition: all .3s ease-in-out;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    bottom: -9%;
    height: 10%;
    background: $brand-secondary;
  }
  &:hover{
    background-color: $brand-secondary!important;
    background-image: url($assets-path + "arrowPurple.svg");
    color: $brand-primary;
  }
  &.backToTop {
    right: 40px;
    position: absolute;
    bottom: 0;
    z-index: 100;
    background-image: url($assets-path + "arrowUp.svg");
    &:after{
      content:none;
    }
    &:hover{
      background-image: url($assets-path + "arrowUpPurple.svg");
    }
  }
}

// Hide native back to top button as we have added one to every page of site with js
.listFooter a.button.backToTop {
  display: none;
}

// Navigation

@media (min-width: map-get($breakpoints, md)) {

  .menuMain{
    z-index: 3; // Prevent page title going over expanded mobile menu - to be fixed in Fresco
  }

}

@media (min-width: map-get($breakpoints, lg)) {

  .menuMain{
    position:relative;
    .topLevel{
      position:absolute;
      top: -153px;
      right: 200px;
      @media (min-width: map-get($breakpoints, xl)) {
        right: 240px;
        top: -150px;
      }
      
      li.level1{
        &> a{
          @media (min-width: map-get($breakpoints, xl)) {
            font-size: $font-size-large;
          }
        }
        &:hover > a{
          position: relative;
          &:before{
            @include beforeafter;
            clip-path: polygon(0 100%, 100% 0, 100% 100%, 0 100%);
            top: -10%;
            height: 10%;
            background: white;
          }
        }
      }

    }

  }

}

// home features

.homeFeature {

  @media (min-width: map-get($breakpoints, md) + 1px) {

    h2 a{
      color: $brand-secondary!important;
      text-decoration: none!important;
    }

    &:nth-child(odd){
      max-width: $container-max-width;
      margin: $spacer*3 auto;
      .homeFeatureDetailsWrapper{
        padding: 0 40px 0 20px!important;
        background: transparent!important;
        p{
          color: $grey-dark!important;
        }
      }
      overflow: visible;
      .home-feature-image-link{
        overflow: visible;
        position: relative;
        &:before{
          @include beforeafter;
          clip-path: polygon(0 0, 100% 66%, 100% 100%, 0 100%);
          height: 3vw;
          background: $brand-secondary;
          top: -3vw;
        } 
        &:after{
          @include beforeafter;
          clip-path: polygon(0 0, 100% 67%, 100% 100%, 0 100%);
          height: 3vw;
          background: white;
          bottom: -1px;
        }  
      }
      &.homeBox3{
        .home-feature-image-link{
          &:before{
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 0);
            background: white;
            top: -1px;
            z-index:2;
          } 
          &:after{
            clip-path: polygon(0 0, 100% 0, 100% 33%, 0 100%);
            background: $brand-secondary;
            bottom: -3vw;
          }  
        }
      }
    }

    &:nth-child(even){
      position: relative;
      margin:0 0 $spacer*3;
      .homeFeatureDetailsWrapper{
        padding: 0 20px 0 40px!important;
      }
      &:before{
        @include beforeafter;
        clip-path: polygon(0 0, 100% 0, 100% 29%, 0 100%);
        height: 3vw;
        background: white;
        top: -1px;
        z-index: 3;
      }  
      &:after{
        @include beforeafter;
        clip-path: polygon(0 0, 100% 67%, 100% 100%, 0 100%);
        height: 3vw;
        background: white;
        bottom: -1px;
        z-index: 3;
      }  
      .home-feature-image-link{
        background-color: transparent;
        &:before{
            content: '';
            position: absolute;
            width: 50%;
            height: 100%;
            background: linear-gradient(90deg, rgba(103,32,135,0) 0%, rgba(103,32,135,1) 100%)!important;
            z-index:2;
            left: 0;
        } 
      }
      .homeFeatureDetailsWrapper{
        background: $brand-primary!important;
        h2 a, p{
          max-width: 600px;
          color:white!important;
        }
      }
      &:hover{
        .banner{
          transform: scale(1.1)!important; /* important instead of nesting inside .homeFeatures */
          transition: all 0.3s ease-in-out!important; /* important instead of nesting inside .homeFeatures */
          opacity: 1!important; /* important instead of nesting inside .homeFeatures */
        }
      }
    }
  }  

  @media (min-width: map-get($breakpoints, xl)) {
    h2 a{
      font-size: $font-size-h2;
    }
  }

  &.homefeaturecategory-homeboximpactstats{
    margin-bottom: 0;
    padding-bottom: $spacer * 3;
    &:before{
      @include beforeafter;
      clip-path: polygon(0 0, 100% 0, 100% 29%, 0 100%);
      height: 3vw;
      background: white;
      top: -1px;
      z-index: 3;
    }  
    &:after{
      content: none;
    }
    img{
      max-height: 100px;
      margin-top: 1rem;
    }
    th, td:nth-of-type(2) {
      order: -1;
      margin-bottom: 1rem;
    }
  }

  &.homefeaturecategory-homeboxquickgiving{
    max-width: 100%;
    margin-top: 0;
    padding-bottom: $spacer * 7;
    &:before{
      content: none;
    }
    &:after{
      @include beforeafter;
      clip-path: polygon(0 0, 100% 67%, 100% 100%, 0 100%);
      height: 3vw;
      background: white;
      bottom: -1px;
      z-index: 3;
    }  
    p{
      display: none;
    }
    .quickGivingPanel .donateBtn{
      &:before, &:after{
        background-color: $brand-primary;
      }
      &:hover{
        background-color: $brand-primary!important;
        color: $brand-secondary;
        background-image: url($assets-path + "arrowOrange.svg");
      }
    }
  }
  

}

.homeFeatureDetailsWrapper .cta-button{
  float:right;
  margin-top:40px;
}

// home feeds
.feedItem{
  margin: 75px 15px;
  //text-align:center;
  overflow: visible;
  &:before{
    @include beforeafter;
    clip-path: polygon(0 0, 100% 66%, 100% 100%, 0 100%);
    height: 10%;
    background: $brand-secondary;
    top: -10%;
  } 
}

// footer

.pageFooter{
  position: relative;
  &:before{
    @include beforeafter;
    clip-path: polygon(0 0,100% 33%,100% 100%,0 100%);
    top: -4vw;
    height: 4vw;
    background: $brand-secondary;
  }
  &:after{
    @include beforeafter;
    clip-path: polygon(0 0,100% 66%,100% 100%,0 100%);
    top: -3vw;
    height: 4vw;
    background: $brand-primary;
  }
}


.footerBox1{
  img{
    margin-bottom:$spacer*2;
    max-width: 265px;
    width: 100%;
  }
}
.footerBox2{
  ul.footerLinks{
    margin-bottom:$spacer*2;
  }
  .socialIcons a{
    margin:0 10px 10px 0;
  }
}
.footerBox3.NewsletterSign-up{
  h1{
    margin-bottom:1em;
  }
  .formQuestion {
    width: 100%!important;
    margin: 0 0 20px!important;
  }
}
.footerBox4{
  margin-top:60px;
  img{
    max-height: 75px;
    margin-right:20px;
  }
}

@media (min-width: map-get($breakpoints, lg) - 1px) {

  .footerBox{
    font-size:inherit;
  }
 .footerBox1{
    grid-column: 3 / span 4;
  }
  .footerBox2{
    grid-column: 7 / span 3;
    padding-top:80px;
  }
  .footerBox3.NewsletterSign-up{
    grid-column: 10 / span 5;
    padding-top:80px;
  }
  .footerBox4{
    grid-column: 3 / span 7;
  }

}

@media (max-width: map-get($breakpoints, lg)) {

  .footerBox{
    font-size: 0.875em;
    &.NewsletterSign-up .groupSignUpBtn{
      font-size: 18px;
    }
  }

}

// Listing

.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem){
  position: relative;
  overflow: visible!important;
  margin: 50px 15px;
  &:before{
    @include beforeafter;
    clip-path: polygon(0 0, 100% 66%, 100% 100%, 0 100%);
    height: 10%;
    background: $brand-secondary;
    top: -10%;
  } 
}

// Post

// Page title over banner

@media (min-width: map-get($map: $breakpoints, $key: md) +1px) { 

  .page-has-banner:not(.page-has-video-banner):not(.blogsPostBody) .post:not(.formWrapper){

    .headerWrapper {
      &:after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background:linear-gradient(45deg, rgba(225,115,38,1) 25%, rgba(225,115,38,0) 75%);
      }
    }

    .headerText{
      max-width: $container-max-width;
      .title{
        background-color: transparent;
        margin:0 0 40px;
        position: absolute;
        bottom:0;
        left:0;
        color:white;
        z-index: 1;
        text-align: left;
        @media (min-width: map-get($map: $breakpoints, $key: lg)) { 
          font-size: 4em;
          max-width: 800px;
        }
      }

    }

  }

}

// Share

.shareWrapper{
  margin-bottom: $spacer * 5;
}

// Quick giving panel

.postContent{
  .quickGivingPanel {
    position: relative;
    margin-top: 6vw;
    &:before{
      @include beforeafter;
      clip-path: polygon(0 0, 100% 66%, 100% 100%, 0 100%);
      height: 4vw;
      background: $brand-secondary;
      top: -4vw;
    }
  }
}

// https://raisingit.atlassian.net/browse/IM-954 Christmas visual appeal
@include appeal-field("PostSystemCategory_christmas-appeal-style", "christmas");

.corp-dream-tree-one,
.corp-dream-tree-two,
.holidays-dream-tree {
  #bodyForm {
    background-color: transparent;
    background-image: url($assets-path + "christmas-field.png");
    background-repeat: repeat-x;
    > *,
    .breadcrumbItem a {
      color: #fff;
    }
  }
}

// 498197 Christmas visual appeal - Enable totaliser
body.PostSystemCategory_christmas-appeal-style{
  .appealTotaliser.totaliserPanel{
    display: block;
  }

  .postAside {
    display: block;
  }
}


// https://raisingit.atlassian.net/browse/CR-288
#mc_embed_signup_scroll,
.mc-field-group {
  display: flex;
  flex-direction: column;
}

.mc-field-group {
  margin-bottom: 1rem;
}

#mc-embedded-subscribe {
  background-color: $brand-secondary;
}